import * as React from 'react'
import toast from 'react-hot-toast'
import type {NextPage} from 'next'
import type {AppProps} from 'next/app'
import Head from 'next/head'
import {DefaultSeo} from 'next-seo'

import {Providers} from '@/client/core/context'

import '@/client/core/styles/css-vars.css'
import '@/client/core/styles/fonts.css'

type PageWithLayout = NextPage & {
  Layout?: keyof JSX.IntrinsicElements | React.ComponentType
}

const Noop = ({children}: {children: React.ReactNode}) => <>{children}</>

const seo = {
  title: '콩크 – 자재 라이브러리',
  description: '크리에이터들의 베이스 캠프',
  siteName: '콩크',
  siteUrl: 'https://concseoul.com',
}

function App({Component, pageProps}: AppProps) {
  const Layout = (Component as PageWithLayout).Layout || Noop

  React.useEffect(() => {
    if (
      window.location.hash ===
      `#message=Confirmation+link+accepted.+Please+proceed+to+confirm+link+sent+to+the+other+email`
    ) {
      toast(
        `이메일 설정을 완료하려면 다른 이메일에 전송된 링크를 클릭해주세요!`,
      )
    }
  }, [])

  return (
    <>
      <DefaultSeo
        title={seo.title}
        description={seo.description}
        openGraph={{
          url: seo.siteUrl,
          title: seo.title,
          description: seo.description,
          images: [{url: '/assets/preview.jpg', alt: seo.description}],
          locale: 'ko-KR',
          site_name: seo.siteName,
        }}
        twitter={{cardType: 'summary_large_image'}}
      />
      <Providers dehydratedState={pageProps.dehydratedState}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Providers>
    </>
  )
}

export default App
