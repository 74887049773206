import * as React from 'react'
import {useIsomorphicLayoutEffect} from 'react-use'
import {jsx} from '@emotion/react'

type ColorMode = 'light' | 'dark'

type ColorModeContextType = [
  ColorMode | undefined,
  React.Dispatch<React.SetStateAction<ColorMode | undefined>>,
]

// ------------------------------
// Utils
// ------------------------------

const colorModes: ColorMode[] = ['light', 'dark']

const STORAGE_KEY = 'color-mode'

const noflash = `(function() { try {
  var mode = localStorage.getItem('color-mode');
  if (!mode) return
  document.documentElement.dataset.colorMode = mode;
} catch (e) {} })();`

function InitializeColorMode() {
  return jsx('script', {
    key: 'no-flash',
    dangerouslySetInnerHTML: {
      __html: noflash,
    },
  })
}

function useColorMode(): ColorModeContextType {
  const context = React.useContext(ColorModeContext)
  if (context === undefined) {
    throw new Error(`useColorMode must be used within a ColorModeProvider.`)
  }
  return context
}

// ------------------------------
// Context
// ------------------------------

const ColorModeContext =
  React.createContext<ColorModeContextType | undefined>(undefined)

ColorModeContext.displayName = 'ColorModeContext'

function ColorModeProvider(props: {children: React.ReactNode}) {
  const [colorMode, setColorMode] = React.useState<ColorMode | undefined>()

  function applyColorMode(colorMode: ColorMode) {
    document.documentElement.dataset.colorMode = colorMode
    localStorage.setItem(STORAGE_KEY, colorMode)
  }

  useIsomorphicLayoutEffect(() => {
    setColorMode(document.documentElement.dataset.colorMode as ColorMode)
  }, [])

  React.useEffect(() => {
    if (colorMode) {
      applyColorMode(colorMode)
    }
  }, [colorMode])

  return (
    <ColorModeContext.Provider value={[colorMode, setColorMode]} {...props} />
  )
}

export {ColorModeProvider, colorModes, InitializeColorMode, useColorMode}
