import {Global} from '@emotion/react'
import {IdProvider} from '@radix-ui/react-id'
import tw, {GlobalStyles, theme} from 'twin.macro'

import {ColorModeProvider} from './color-mode-context'
import {MinScreenProvider} from './min-screen-context'

const selectionStyles = {
  '::selection': tw`bg-highlight text-text`,
}

const bodyStyles = {
  html: tw`font-size[16px] sm:font-size[15px]`,
  body: tw`font-sans font-normal text-base text-text bg-background antialiased`,
}

/**
 * A collection of providers for UI design
 */
function ThemeProvider({children}: {children: React.ReactNode}) {
  return (
    <IdProvider>
      <GlobalStyles />
      <Global styles={[selectionStyles, bodyStyles]} />
      <ColorModeProvider>
        <MinScreenProvider screens={theme`screens`}>
          {children}
        </MinScreenProvider>
      </ColorModeProvider>
    </IdProvider>
  )
}

export {ThemeProvider}
