import * as React from 'react'

const defaultValue = {}

const ScreensContext =
  React.createContext<Record<string, boolean>>(defaultValue)

ScreensContext.displayName = 'ScreensContext'

const MinScreenProvider = ({
  children,
  screens,
}: {
  children: React.ReactNode
  screens: string
}) => {
  const [queryMatch, setQueryMatch] = React.useState({})

  React.useEffect(() => {
    const mediaQueryLists: Record<string, MediaQueryList> = {}
    let isAttached = false

    const mediaData = Object.entries(screens).map(([name, media]) => [
      name,
      `(min-width: ${media})`,
    ])

    const handleQueryListener = () => {
      const updatedMatches = mediaData.reduce(
        (acc, [name]) => ({
          ...acc,
          [name]: Boolean(
            mediaQueryLists[name] && mediaQueryLists[name].matches,
          ),
        }),
        {},
      )
      setQueryMatch(updatedMatches)
    }

    if (window && window.matchMedia) {
      const matches: Record<string, boolean> = {}

      mediaData.forEach(([name, media]) => {
        if (typeof media !== 'string') {
          matches[name] = false
          return
        }
        mediaQueryLists[name] = window.matchMedia(media)
        matches[name] = mediaQueryLists[name].matches
      })

      setQueryMatch(matches)
      isAttached = true

      mediaData.forEach(([name, media]) => {
        if (typeof media !== 'string') return
        mediaQueryLists[name].addListener(handleQueryListener)
      })
    }

    return () => {
      if (!isAttached) return
      mediaData.forEach(([name, media]) => {
        if (typeof media !== 'string') return
        mediaQueryLists[name].removeListener(handleQueryListener)
      })
    }
  }, [screens])

  return (
    <ScreensContext.Provider value={queryMatch}>
      {children}
    </ScreensContext.Provider>
  )
}

const useMinScreen = () => {
  const context = React.useContext(ScreensContext)
  if (context === defaultValue)
    throw new Error(`useMinScreen must be used within a MinScreenProvider`)

  return {
    min: (size: TemplateStringsArray) => context[size as unknown as string],
  }
}

export {MinScreenProvider, useMinScreen}
